
import { defineComponent, reactive, toRefs } from 'vue';
import errGif from '@/assets/401-images/401.gif';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const data = reactive({
      errGif: errGif + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false,
      back() {
        if (route.query.noGoBack) {
          router.push({ path: '/dashboard' }).catch(err => {
            console.warn(err);
          });
        } else {
          router.go(-1);
        }
      }
    });

    return { ...toRefs(data) };
  }
});

